// External dependecies
import { Div, Text } from 'atomize';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

// Local dependencies
import LabelText from '@entities/articles/ui/card/LabelText';
import DateFormat from '@shared/utils/DateFormat';
import ArrowRightIcon from '@svg/ArrowRight';
import './style.scss';

export interface CustomCardProps {
  bgColor?: string;
  children?: string;
  cardAlign?: string;
  color?: any;
  date?: any;
  linkLeft?: string;
  justifyFlex?: string;
  flexDir?: any;
  image?: any;
  linkToArticle?: string;
  paddingCard?: string;
  textSizeTitle?: string;
  textLabel?: string;
}

export default function ArticleCard({
  bgColor,
  children,
  cardAlign,
  color,
  linkLeft,
  date,
  justifyFlex,
  flexDir,
  image,
  linkToArticle,
  paddingCard,
  textSizeTitle,
  textLabel,
}: CustomCardProps) {
  return (
    <Link to={linkToArticle} style={{ display: 'flex' }}>
      <Div
        bg={bgColor ? bgColor : 'white'}
        d="flex"
        rounded="20px"
        overflow="hidden"
        justify={{ xs: 'start', lg: justifyFlex }}
        flexDir={{ xs: 'column-reverse', lg: flexDir }}
        align={{ xs: 'start', lg: cardAlign }}
        m={{ b: '20px' }}
        pos="relative"
      >
        <Div p={{ r: '24px' }}>
          <Div
            p={{
              t: '23px',
              l: { xs: '10px', sm: '20px', lg: paddingCard },
              b: '80px',
            }}
          >
            <Div m={{ b: '16px' }}>
              <LabelText color={color} text={textLabel!} />
            </Div>
            <Div m={{ b: '24px' }}>
              <Text
                tag="h3"
                m={{ b: '12px' }}
                textSize={{ xs: 'display1', lg: textSizeTitle }}
              >
                {children}
              </Text>
              <Text
                d="flex"
                style={{ gap: '5px' }}
                tag="p"
                textSize={{ xs: 'smallSize1', sm: 'body' }}
                textWeight={{ xs: 'titleBlockLink', sm: 'body' }}
                textColor="grayTextInCard"
              >
                <DateFormat date={date} />
                <Text tag="span">|</Text>
                Айжан Сагынова
              </Text>
            </Div>
            <Div
              m={{ b: '12px' }}
              bg="bg_article_card"
              w="105px"
              p={{ y: '9px' }}
              style={{ gap: '10px' }}
              textColor="btn_primary_bg"
              d="flex"
              align="center"
              justify="center"
              rounded="4px"
              pos="absolute"
              bottom="20px"
              left={{ xs: '15px', lg: linkLeft }}
            >
              <Text
                tag="p"
                textColor="btn_hover_border_white"
                textSize="smallSize1"
                textWeight="titleBlockLink"
              >
                Читать
              </Text>
              <ArrowRightIcon fill="#6574CF" />
            </Div>
          </Div>
        </Div>
        <Div overlow="hidden" style={{ flex: '0 0 40%' }}>
          <GatsbyImage
            className="article-image"
            image={image}
            alt="image"
            objectFit="cover"
          />
        </Div>
      </Div>
    </Link>
  );
}
