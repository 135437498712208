// External dependnecies
import React from 'react';

// Local dependencies
import { IconProps } from './types';

export default function ArrowRightIcon({ fill }: IconProps) {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 15.9955L2.24129 17.5L10.6667 9L2.24129 0.5L0.75 2.0045L7.68408 9L0.75 15.9955Z"
        fill={fill}
      />
    </svg>
  );
}
