//External dependensies
import { Div } from 'atomize';
import React from 'react';

//Local dependensies
import ArticlesQrCodeIcon from '../../../svg/ArticlesQrCodeIcon';

interface Props {
  width?: number;
  height?: number;
}

export default function DownloadAppBlockQrCode({ width = 148, height = 148 }: Props) {
  return (
    <Div bg="white" w={width} h={height} p="13px" rounded="15px" shadow="4">
      <ArticlesQrCodeIcon width={width} height={height} />
    </Div>
  );
}
