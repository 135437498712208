/* External dependencies */
import { Col, Container, Row } from 'atomize';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useSelector } from 'react-redux';

/* Local dependencies */
import { RootState } from '@app/redux/store';
import DownloadAppBlock from '@components/website/downloadAppBlock/DownloadAppBlock';
import Footer from '@components/website/footer/Footer';
import { getLabelColor } from '@entities/articles/lib/getLabelColor';
import ArticleCard from '@entities/articles/ui/articleCard/ArticleCard';
import ArticleHeader from '@entities/articles/ui/articles-header/Articles-header';
import Layout from '@shared/ui/layout/WebLayout';
import SEO from '@shared/ui/layout/seo';

export default function ArticleDetail({ data }) {
  const { nodes } = data?.allArticle || '';
  const { isBanner } = useSelector((state: RootState) => state?.banner);
  const { t } = useI18next();

  return (
    <Layout>
      <SEO
        title={t('magazinePageTitle')}
        description={t('magazinePageContent')}
      />
      <ArticleHeader />
      <Container>
        <Row m={{ t: { xs: '100px', lg: isBanner ? '200px' : '111px' } }}>
          {nodes &&
            nodes.map((article, index) => {
              const image =
                article.heroMedia.imageUrl.childImageSharp.gatsbyImageData;
              const color = getLabelColor(article.category);
              const url = `/articles/${article.slug}`;

              return (
                <Col
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  size={{ xs: '12', md: '6', lg: '4' }}
                  d="flex"
                  key={index}
                >
                  <ArticleCard
                    date={article.createDate}
                    image={image}
                    flexDir="column-reverse"
                    textLabel={article.category}
                    linkToArticle={url}
                    textSizeTitle="display1"
                    paddingCard="20px"
                    color={color}
                    justifyFlex="start"
                    bgColor="gray"
                    cardAlign="start"
                    linkLeft="15px"
                  >
                    {article.title}
                  </ArticleCard>
                </Col>
              );
            })}
        </Row>
        <DownloadAppBlock />
      </Container>
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query ($item: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
      }
    }
    allArticle(
      sort: { fields: createDate, order: DESC }
      filter: { category: { eq: $item } }
    ) {
      nodes {
        articleType
        category
        createDate
        heroMedia {
          imageUrl {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        readTimeMinute
        slug
        synopsis
        title
        id
      }
    }
  }
`;
