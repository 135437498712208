//external dependensies
import { Div, Text } from 'atomize';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

// Local dependensies
import ArticlesQrCodeIcon from '../downloadAppBlockQrCode/DownloadAppBlockQrCode';
import DownloadAppButtons, {
    DownloadAppButton,
} from '../downloadAppButtons/DownloadAppButtons';

export default function DownloadAppBlock() {
  const data = useStaticQuery(query);
  const articlesInApp = getImage(data?.articlesInApp);
  const app: DownloadAppButton[] = [
    {
      store: 'android',
      link: 'https://play.google.com/store/search?q=medcheck&c=apps&hl=ru',
    },
    {
      store: 'apple',
      link: 'https://apps.apple.com/kg/app/medcheck/id1578873351',
    },
  ];

  return (
    <Div
      w="100%"
      bg="linear-gradient(90deg, rgba(251, 231, 133, 0.2) 0%, rgba(151, 234, 240, 0.2) 100%)"
      rounded="20px"
      p={{ x: '30px', t: '30px' }}
      d="flex"
      flexDir={{ xs: 'column', md: 'row' }}
      justify="space-between"
      align={{ xs: '', md: 'center' }}
      m={{ b: '40px' }}
    >
      <Div maxW={{ xs: '100%', md: '380px' }} m={{ r: '20px' }}>
        <Text
          tag="h3"
          textSize={{
            xs: 'display2',
            sm: 'errorTitle',
            lg: 'subtitle',
            xl: 'downloadAppTitle',
          }}
          textWeight="700"
          m={{ b: '20px' }}
        >
          <Trans>haveMagazineInTheAppText</Trans>
        </Text>
        <Div d={{ xs: 'block', sm: 'flex' }} style={{ gap: '5px' }}>
          {app?.map((item, index) => (
            <Div
              key={index}
              w={{ xs: '180px', sm: '290px', md: '100%' }}
              m={{ y: '10px' }}
            >
              <DownloadAppButtons store={item.store} link={item.link} />
            </Div>
          ))}
        </Div>
      </Div>
      <Div d={{ xs: 'none', lg: 'block' }}>
        <ArticlesQrCodeIcon />
      </Div>
      <Div
        maxW={{ xs: '100%', md: '430px' }}
        m={{ l: { xs: '0', md: '20px' }, t: { xs: '20px', md: '0' } }}
      >
        <GatsbyImage image={articlesInApp} alt="articlesInApp" />
      </Div>
    </Div>
  );
}

export const query = graphql`
  query {
    articlesInApp: file(relativePath: { eq: "articles-in-app.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
